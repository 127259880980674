<template>
  <section id="video">
    <video
      id="videoplayer"
      muted="true"
      loop
      playsInline="playsinline"
      preload="metadata"
      @click="play()"
    >
      <source src="../assets/video/thePool-2023.mp4" type="video/mp4" />
      <source src="../assets/video/thePool-2023.webm" type="video/webm" />
      Your browser does not support the video tag.
    </video>
  </section>
</template>

<script>
export default {
  name: "Video",
  data() {
    return {
      animation: null,
      trigger: null,
      video: null,
    };
  },
  methods: {
    checkScroll() {
      let offset = this.trigger.getBoundingClientRect().top;
      if (offset <= 0.5) {
        this.video.play();
      } else {
        this.video.pause();
      }
    },
    play() {
      document.getElementById("videoplayer").play();
    },
  },
  beforeDestroy() {
    document
      .getElementById("home")
      .removeEventListener("scroll", this.checkScroll);
  },
  mounted() {
    this.trigger = document.getElementById("video");
    this.video = document.getElementById("videoplayer");
    document
      .getElementById("home")
      .addEventListener("scroll", this.checkScroll);
  },
  // mounted() {
  //   const video = document.getElementById("videoplayer");
  //   video.muted = true;
  //   const promise = video.play();

  //   if (promise !== undefined) {
  //     promise.catch(() => {
  //       video.setAttribute("controls", "true");
  //     });
  //   }
  // },
};
</script>

<style lang="scss" scoped>
#video {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  video {
    position: absolute;
    left: 50%; /* % of surrounding element */
    top: 50%;
    transform: translate(-50%, -50%); /* % of current element */

    height: 100%;
    width: 177.77777778vh; /* 100 * 16 / 9 */
    min-width: 100%;
    min-height: 56.25vw; /* 100 * 9 / 16 */

    // max-width: 100%;
    // height: 100%;
  }
}
</style>
