<template>
  <header><h1 v-html="content"></h1></header>
</template>

<script>
export default {
  name: "Header",
  props: {
    content: String,
  },
};
</script>

<style lang="scss" scoped>
header {
  display: flex;
  justify-content: center;
  align-items: center;

  width: auto;
  height: 100vh;
  height: -webkit-fill-available;
  padding: 0 5%;

  // background-color: $blue;
  color: $white;

  text-align: center;
  text-transform: uppercase;
}
</style>
